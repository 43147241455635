// Vendors
import Vue from 'vue';
import axios from 'axios';
import NavMobile from '../components/navmobile';

axios.defaults.withCredentials = true;

class App {

    constructor() {}

    init() {

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            components: {
                'navmobile': NavMobile
            },
            data() {
                return {
                    search: '',
                    people: null,
                    categories: null,
                    checkedCategories: [],
                    loading: true
                }
            },
            mounted () {
                axios.all([
                    this.getPersons()
                ])
            },
            methods: {
                getPersons() {
                    axios
                        .get('/api/persons')
                        .then(response => {
                            this.people = response.data.persons;
                            this.loading = false;
                        });
                }
            },
            computed: {
                filterPeople: function () {

                    let self = this;

                    if (!self.checkedCategories.length && self.search === '') {

                        return self.people;

                    } else if (!self.checkedCategories.length && self.search !== '') {

                        return this.people.filter(person => {
                            return person.title.toLowerCase().includes(this.search.toLowerCase())
                        });

                    } else {

                        let people = self.people.filter(person => {
                            return person.title.toLowerCase().includes(self.search.toLowerCase())
                        });

                        return people.filter(function (person) {
                            return person.categories.some(function (categories) {
                                return self.checkedCategories.indexOf(categories) !== -1
                            })
                        });
                    }
                }
            }
        });
    }
}

const app = new App();
app.init();
