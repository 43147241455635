var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "c-nav-mobile" }, [
    _c("input", {
      staticClass: "c-nav-mobile-state",
      attrs: { type: "checkbox", id: _vm.id }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "c-nav-mobile-button",
        attrs: { for: _vm.id },
        on: { click: _vm.toggle }
      },
      [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-nav-mobile-content",
        class: "c-nav-mobile-content--" + _vm.orientation
      },
      [
        _c(
          "div",
          {
            staticClass:
              "c-nav-mobile-content-inner o-flex o-flex-auto o-flex-nowrap o-flex-column o-flex-justify-content-between"
          },
          [
            _c(
              "ul",
              { staticClass: "c-nav-mobile-content-list" },
              _vm._l(_vm.items, function(item) {
                return _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "u-padding-xs",
                      class: item.class,
                      attrs: { href: item.url }
                    },
                    [
                      _c("span", { staticClass: "u-padding-bottom-xs" }, [
                        _vm._v(_vm._s(item.label))
                      ])
                    ]
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-nav-mobile-content-buttons" }, [
      _c(
        "a",
        {
          staticClass:
            "c-button c-button-full c-button-primary u-margin-bottom-xs",
          attrs: { href: "#opdracht" }
        },
        [_vm._v("Ik heb een opdracht")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "c-button c-button-full c-button-primary u-margin-bottom-xs",
          attrs: { href: "#freelancer" }
        },
        [_vm._v("Ik zoek een freelancer")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }